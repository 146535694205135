.pago-unidad-card-primary {
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    padding: 1rem 1rem;
    font-weight: bold;
}

.pago-unidad-card-secondary {
    border-radius: 5px;
    display: flex;
    flex-flow: column;
    padding: 1rem 1rem;
    font-weight: bold;

}

.pago-unidad-card-contenedor {
    display: flex; /* Activa Flexbox */
    justify-content: flex-start; /* Espacio entre los recuadros */
    align-items: center; /* Centra los recuadros verticalmente */
    gap: .5rem; /* Espacio entre los recuadros */
    flex-wrap: wrap;
}

.recuadro {
    min-width: 120px; /* El recuadro nunca será más pequeño que 120px */
    flex-grow: 1; /* Los recuadros se expanden para ocupar el espacio disponible */
    flex-basis: auto; /* Los recuadros comienzan ocupando el 18% del contenedor */
    height: auto; /* Altura fija para los recuadros */
    display: flex; /* Flexbox dentro del recuadro */
    justify-content: center; /* Centra el texto horizontalmente */
    align-items: center; /* Centra el texto verticalmente */
    word-wrap: break-word; /* Permite que el texto se divida si es muy largo */
    border-radius: 8px; /* Bordes redondeados */
    box-sizing: border-box; /* Asegura que el padding se incluya dentro del tamaño total del recuadro */
  }
  
  .recuadro .pago-unidad-card-primary {
    background: #1451A5;
    color: white;
    margin-left: 1.5rem;
  }
  .recuadro .pago-unidad-card-secondary{
    background: #F9F9F9;
  }

  /* Ajuste para pantallas más pequeñas */
  @media (max-width: 768px) {
    .recuadro {
      flex-basis: 45%; /* Los recuadros ocupan el 45% en pantallas medianas */
    }
  }
  
  @media (max-width: 480px) {
    .recuadro {
      flex-basis: 100%; /* En pantallas pequeñas, cada recuadro ocupa el 100% */
    }
  }